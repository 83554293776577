.involved {
  margin-bottom: 300px;
  text-align: center;

  &__title {
    margin-bottom: 22px;
  }

  &__text {
    margin-bottom: 38px;
  }

  &__preview {
    margin-bottom: 40px;
    img {
      display: block;
      width: 100%;
    }
  }
}

@include tablet {
  .involved {
    margin-bottom: 194px;

    &__text {
      margin-bottom: 48px;
    }
  }
}

@include desktop {
  .involved {
    margin-bottom: 194px;
    text-align: left;
    
    &__inner {
      display: flex;
      position: relative;
      height: 540px;
      padding-left: 600px;
      flex-direction: column;
      justify-content: center;
    }
    
    &__preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 520px;
      margin-bottom: 0;
    }

    &__text {
      max-width: 540px;
    }
  }
}

@include desktop-wide {
  .involved {
    
    &__inner {
      padding-left: 646px;
    }
  }
}