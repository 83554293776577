.follow {
  &__title {
    font-weight: 700;
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    display: flex;
    height: 66px;
    border: 1px solid #4B4B4B;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    transform: scale(.8);
    flex: 0 0 66px;

    &:hover {
      background: rgba(#4B4B4B, 0.5);
    }

    .icon {
      fill: white;
    }
  }
}

@include tablet {
  .follow {

    &__title {
      margin-bottom: 20px;
      font-size: 22px;
    }

    &__item {
      margin: 0 20px 0 0;
      transform: scale(1);
    }
  }
}


@include desktop {
  .follow {

    &__list {
      justify-content: flex-start;
    }
  }
}