.center {
  padding: 0 30px;
}

@include tablet {
  .center {
    max-width: 1280px + 80px;
    margin: 0 auto;
    padding: 0 40px;
    
    &_sm {
      max-width: 850px + 80px;
    }
  }
}