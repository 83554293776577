html {
  background: black;
}

body {
  @include f;

  font-size: 14px;
  line-height: (23 / 14);
  color: white;
  
  -webkit-font-smoothing: antialiased;
  
  opacity: 0;
  transition: opacity .25s;
  &.loaded {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}

@include tablet {
  body {
    font-size: 18px;
    line-height: (28 / 18);
  }
}