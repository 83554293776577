.intro {
  margin-bottom: 62px;
  text-align: center;

  &__title {
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 30px;
    p {
      display: inline;
    }
  }

  &__link {
    display: inline-flex;
    margin: 0 auto;
    color: white;
    align-items: center;

    &:hover {
      .icon {
        transform: translateX(8px);
      }
    }

    .icon {
      margin-left: 24px;
      fill: white;
      transition: transform 0.2s;
    }
  }
}

@include tablet {
  .intro {
    margin-bottom: 124px;
    text-align: left;

    &__title {
      margin-bottom: 44px;
    }

    &__text {
      column-count: 2;
      column-gap: 64px;
      p {
        display: block;
      }
    }

    &__link {
      margin: 0;
      font-size: 16px;
    }
  }
}