.bg {
  position: absolute;
  left: calc(50% - 420px);
  width: 860px;
  pointer-events: none;
  z-index: -2;
  
  &_wave {
    left: calc(50% - 207px);
    width: 414px;
    opacity: .5;
  }

  img {
    display: block;
    width: 100%;
  }
}

@include mobile {
  .bg {

    &_wave {
      left: calc(50% - 420px);
      width: 860px;
    }
  }
}

@include tablet {
  .bg {
    left: calc(50% - 840px);
    width: 1680px;
    
    &_wave {
      opacity: 1;
    }
  }
}