.share {
  text-align: center;

  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
  }

  &__tag {
    font-size: 20px;
    font-weight: 700;
    color: #818EC7;
  }

  &__list {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    margin: 0 22px;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(2px);
    }
  }

  .icon {
    fill: white;
  }
}

@include tablet {
  .share {
    
    &__title {
      margin-bottom: 2px;
      font-size: 22px;
    }

    &__tag {
      font-size: 32px;
    }

    &__item {
      margin: 0 30px;
    }

    .icon {
      width: 54px;
      height: 46px;
    }
  }
}