.icon {
  &-arrow-down {
    width: 30px;
    height: 39px;
  }

  &-arrow {
    width: 18px;
    height: 14px;
  }

  &-facebook,
  &-instagram,
  &-twitter {
    width: 30px;
    height: 30px;
  }

  &-join {
    width: 58px;
    height: 58px;
  }

  &-share {
    width: 18px;
    height: 20px;
  }
}