.dropdown {
	position: relative;
	display: inline-block;
	
	&:hover &__body,
	&.open &__body {
		opacity: 1;
		pointer-events: all;
	}
	
	&__btn {
		position: relative;
		width: 100%;
		z-index: 12;
	}

	&__body {
		position: absolute;
		top: calc(100% + 20px);
		left: 50%;
		padding: 35px 40px 40px;
		min-width: 260px;
		text-align: left;
		transform: translateX(-50%);
		box-shadow: 0 7px 72px 0 rgba(black, .1);
		background: white;
		opacity: 0;
		transition: opacity .25s;
		pointer-events: none;
		z-index: 10;
		
		&:before,
		&:after {
			content: '';
			position: absolute;
		}

		&:before {
			content: "";
			position: absolute;
			top: -15px;
			left: 50%;
			width: 0;
			height: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 15px solid white;
			transform: translateX(-50%);
		}

		&:after {
			content: "";
			position: absolute;
			top: -50px;
			left: 0;
			width: 100%;
			height: 50px;
		}

		li {
			&:not(:last-child) {
				margin-bottom: 22px;
			}
		}
		a {
			position: relative;
			padding-left: 35px;
			color: black;
			transition: color .2s;
			&:hover {
				color: #5866A4;
				svg {
					fill: #5866a4;
				}
			}
		}
		svg {
			position: absolute;
			top: 50%;
			left: 0;
			fill: black;
			transform: translateY(-50%);
			transition: fill .2s;
		}
	}
}