.text {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.625;

  a {
    color: #818EC7;
  }
}

@include tablet {
  .text {
    font-size: 24px;
    line-height: 1.5;
  }
}