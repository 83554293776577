.playlists {
  position: relative;
  margin-bottom: 110px;

  &__bg {
    top: -306px;
  }

  &__head {
    margin-bottom: 58px;
    text-align: center;
  }

  &__title {
    margin-bottom: 18px;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__preview {
    img {
      display: block;
      width: 100%;
    }
  }

  &__col {
    &:first-child {
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 30px;
        height: 100%;
        background: linear-gradient(to right, black, transparent);
        z-index: 4;
      }
      &:after {
        left: auto;
        right: -30px;
        transform: rotate(180deg);
      }
    }
  }

  &__nav {
    display: flex;
    margin: 0 -30px 30px;
    padding-bottom: 4px;
    overflow: auto;
    
    &:before,
    &:after {
      content: '';
      flex: 0 0 20px;
    }
  }

  &__item {
    position: relative;
    margin: 0 10px;
    padding: 6px;
    border-bottom: 1px solid transparent;
    font-weight: 700;
    color: white;
    white-space: nowrap;
    transition: border-color .2s;
    
    &.active {
      border-color: white;
      
      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
        @include arr(10, 5, white, b);
      }
    }
    
    .icon {
      display: none;
    }
  }

  iframe {
    display: block;
    width: 100%;
    height: 464px;
  }
}

@include mobile {
  .playlists {

    &__bg {
      top: -540px;
    }

  }
}

@include tablet {
  .playlists {
    margin-bottom: 200px;
    
    &__bg {
      top: 100px;
    }
    
    &__head {
      margin-bottom: 108px;
    }

    &__row {
      display: flex;
      margin: 0 -40px;
    }

    &__col {
      padding: 0 40px;
      flex: 1;
    }

    &__nav {
      display: block;
      margin: 0;
      padding: 0;
      overflow: visible;

      &:before,
      &:after {
        display: none;
      }
    }

    &__item {
      display: flex;
      position: relative;
      height: 87px;
      margin: 0 auto 12px;
      padding: 0 32px 0 36px;
      background: #1B1D22;
      border: none;
      font-size: 20px;
      align-items: center;
      overflow: hidden;

      &:hover,
      &.active {
        .icon {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &.active {
        &:before {
          transform: translateX(0);
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 6px;
        height: 100%;
        background-image: linear-gradient(180deg, #818EC7 0%, #A1BFDF 28%, #C4E2D3 100%);
        transform: translateX(-6px);
      }

      &:after {
        display: none;
      }

      .icon {
        display: block;
        margin-left: auto;
        fill: white;
        opacity: 0;
        transform: translateX(12px);
        transition: opacity 0.2s, transform 0.2s;
      }
    }

    &__title {
      margin-bottom: 30px;
    }

    &__text {
      margin-bottom: 38px;
    }

    iframe {
      height: 582px;
    }
  }
}

@include desktop {
  .playlists {
    margin-bottom: 250px;
  }
}
