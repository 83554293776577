.btn {
  display: inline-flex;
  height: 50px;
  padding: 0 16px;
  background: white;
  border: 1px solid white;
  font-size: 14px;
  color: black;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transition: background 0.2s, color .2s;

  &:hover,
  &:active {
    background: #f6f5f5;
    color: black;
  }

  &_stroke {
    background: none;
    color: white;
    &:hover,
    &:active {
      background: #f6f5f5;
      color: black;
    }
  }

  .icon {
    margin-right: 12px;
  }
}

@include tablet {
  .btn {
    min-width: 200px;
    font-size: 16px;
  }
}