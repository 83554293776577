@mixin mobile {
  @media only screen and (min-width: 414px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop-wide {
  @media only screen and (min-width: 1260px) {
    @content;
  }
}

@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if ($direction == t) {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent;
  }
  @if ($direction == r) {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg;
  }
  @if ($direction == b) {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent;
  }
  @if ($direction == l) {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent;
  }
  @if ($direction == tl) {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent;
  }
  @if ($direction == tr) {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent;
  }
  @if ($direction == br) {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent;
  }
  @if ($direction == bl) {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg;
  }
}

@mixin f {
  font-family: "aktiv-grotesk", sans-serif;
}