.header {
  margin-bottom: 18px;
  
  &__logo {
    display: block;
    width: 130px;
    margin: 0 auto;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__btns {
    display: flex;
    margin: 0 -30px 65px;
    border-bottom: 1px solid #404040;
    justify-content: center;
  }

  &__btn,
  &__dropdown {
    flex: 0 0 50%;
  }

  .btn {
    height: 45px;
    border-color: transparent;
  }
}

@include tablet {
  .header {
    margin-bottom: 86px;
    padding: 24px 0;

    &__logo {
      width: 185px;
    }

    &__btns {
      border: none;
    }
    
    &__btn,
    &__dropdown {
      max-width: 200px;
      margin-left: 20px;
      flex: 0;
    }

    .btn {
      height: 50px;
      border-color: white;
    }
  }
}

@include desktop-wide {
  .header {

    &__center {
      display: flex;
      max-width: 1460px;
      padding-left: 480px;
      flex-direction: row-reverse;
      align-items: center;
    }

    &__btns {
      margin: 0;
    }
  }
}

