.video {
  margin-bottom: 296px;
  
  &__item {
    display: block;
    position: relative;
    cursor: pointer;
    z-index: 2;
    
    img {
      display: block;
      width: 100%;
      min-height: 230px;
      object-fit: cover;
    }

    svg {
      position: absolute;
      top: calc(50% - 47px);
      left: calc(50% - 47px);
      transform: scale(.7);
    }
  }
}

@include tablet {
  .video {
    margin-bottom: 180px;

    &__item {

      &:hover {
        svg {
          transform: scale(1.05);
        }
      }

      svg {
        transform: scale(1);
        transition: transform 0.2s;
      }
    }
  }
}