.main {
  position: relative;
  margin-bottom: 96px;
  text-align: center;

  &__bg {
    top: 196px;
  }

  &__center {
    position: relative;
    z-index: 2;
  }

  &__caption {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;

    a {
      color: #818ec7;
    }
  }

  &__preview {
    max-width: 240px;
    margin: 0 auto 24px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__text {
    max-width: 1020px;
    margin: 0 auto 24px;
  }

  &__btn {
    min-width: 200px;
  }

  &__more {
    margin-top: 126px;
    font-size: 16px;
    font-weight: 700;

    .icon {
      display: block;
      margin: 14px auto 0;
      fill: white;
    }
  }
}

@include tablet {
  .main {
    margin-bottom: 140px;

    &__bg {
      top: -184px;
    }

    &__caption {
      margin-bottom: 40px;
      font-size: 20px;
    }

    &__preview {
      max-width: 467px;
      margin-bottom: 40px;
    }

    &__text {
      margin-bottom: 36px;
    }

    &__more {
      margin-top: 96px;
    }
  }
}
