.join {
  position: relative;
  padding-bottom: 400px;
  text-align: center;

  &__bg {
    &:first-child {
      top: -312px;
      transform: scale(-1, 1);
    }

    &:last-child {
      bottom: 0;
    }
  }

  &__head {
    max-width: 850px;
    margin: 0 auto 46px;
  }

  &__icon {
    margin-bottom: 22px;
    font-size: 0;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 22px;
  }

  &__row {
    margin-bottom: 48px;
  }

  &__col {
    margin-bottom: 30px;
    padding: 36px 40px 68px;
    background: #1B1D22;
  }

  &__subtitle {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
  }

  &__content {
    margin-bottom: 26px;
  }

  &__btn {
    width: 224px;
  }
}

@include mobile {
  .join {
    
    &__bg {
      &:first-child {
        top: -520px;
      }
    }
  }
}

@include tablet {
  .join {
    padding-bottom: 870px;
    
    &__bg {
      &:first-child {
        top: 100px;
      }
    }

    &__head {
      margin-bottom: 96px;
    }

    &__icon {
      margin-bottom: 32px;
    }

    &__text {
      margin-bottom: 38px;
    }

    &__row {
      display: flex;
      margin: 0 -30px 100px;
      justify-content: center;
    }

    &__col {
      max-width: 500px;
      margin: 0 30px;
      padding: 70px 40px 88px;
      flex: 1;
    }

    &__subtitle {
      margin-bottom: 16px;
      font-size: 38px;
    }

    &__content {
      margin-bottom: 36px;
    }

    &__btn {
      height: 66px;
    }
  }
}