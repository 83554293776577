.title {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 700;
  line-height: (44 / 34);
  a {
    color: #818EC7;
  }
}

@include tablet {
  .title {
    font-size: 56px;
    line-height: (70 / 56);
  }
}